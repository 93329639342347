import { styled } from "@mui/material";
import React, { PropsWithChildren, useMemo } from "react";
import { usePlayerContext } from "../../contexts/PlayerContext";
import MusicPlayer from "./audio";

const MainContainer = styled('div')(({ theme }) => ({
    [`&.player-active`]: {
        paddingBottom: 'var(--player-height)',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 'var(--player-height-sm)',
        },
        [`& > .player-container`]: {
            transform: 'translate(0,calc(var(--player-height) * -1))',
            [theme.breakpoints.down('sm')]: {
                transform: 'translate(0,calc(var(--player-height-sm) * -1))',
            }
        }
    }
}));

const MediaPlayerContainer = styled('div')(({ theme }) => ({
    position: 'fixed',
    zIndex: 1000,
    bottom: "calc(var(--player-height) * -1)",
    left: 0,
    width: '100%',
    transition: 'all 0.5s ease',
    opacity: 0.9,
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
    [theme.breakpoints.down('sm')]: {
        bottom: "calc(var(--player-height-sm) * -1)",
    }
}));

export interface PlayerContainerProps extends PropsWithChildren<any> {
    name?: string;
}

export const PlayerContainer: React.FC<PlayerContainerProps> = ({ children }) => {
    const {
        activeDuration,
        activeIndex,
        isPlaying,
        position,
        tracks,
        volume,
        setVolume,
        setActiveIndex,
        setActiveDuration,
        setIsPlaying,
        setPosition,
        nextTrack
    } = usePlayerContext();

    const className = useMemo(() => {
        return tracks?.length || isPlaying ? 'player-active' : '';
    }, [tracks, isPlaying]);

    return (
        <MainContainer className={className}>
            {children}
            <MediaPlayerContainer className="player-container">
                <MusicPlayer tracks={tracks}
                    duration={activeDuration}
                    trackIndex={activeIndex}
                    isPlaying={isPlaying}
                    position={position}
                    volume={volume}
                    onTrackEnd={nextTrack}
                    onDurationChange={setActiveDuration}
                    onVolumeChange={(vol) => setVolume(vol)}
                    onTrackChange={(trackIndex) => {
                        setActiveIndex(trackIndex)
                    }}
                    onPositionChange={(pos) => setPosition(pos)}
                    onPlay={() => {
                        setIsPlaying(true);
                    }}
                    onPause={() => {
                        setIsPlaying(false);
                    }} />
            </MediaPlayerContainer>
        </MainContainer>
    )
}