/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, createContext, useCallback, useState } from "react";
import { RepeatMode } from "../constants/MusicPlayer";
import { truncate } from "fs";

export interface PlayerContextProps {
    volume: number;
    setVolume: (vol: number) => void;
    activeIndex: number;
    setActiveIndex: (index: number) => void;
    activeDuration: number;
    setActiveDuration: (duration: number) => void;
    isPlaying: boolean;
    setIsPlaying: (isPlaying: boolean) => void;
    position: number;
    setPosition: (pos: number) => void;
    tracks: Track[];
    repeatMode: RepeatMode;
    setRepeatMode: (mode: RepeatMode) => void;
    playTracks: (tracks: Track[], index?: number) => void;
    setTracks: (tracks: Track[]) => void;
    nextTrack: () => void;
    togglePlayPause: () => void;
}

export const PlayerContext = createContext<PlayerContextProps | undefined>(undefined);


export const PlayerProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
    const [volume, setVolume] = useState(30);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeDuration, setActiveDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [position, setPosition] = useState(0);
    const [playlist, setPlaylist] = useState<Track[]>([] as Track[]);
    const [repeatMode, setRepeatMode] = useState<RepeatMode>(RepeatMode.NONE);

    const nextTrack = useCallback(() => {
        switch (repeatMode) {
            case RepeatMode.NONE:
                if (activeIndex < playlist.length - 1) {
                    setActiveIndex(() => activeIndex + 1)
                } else {
                    setIsPlaying(false);
                }
                break;
            case RepeatMode.ONE:
                setIsPlaying(() => false);
                setTimeout(() => {
                    setPosition(() => 0)
                    setIsPlaying(() => true);
                }, 300);
                break;
            case RepeatMode.ALL:
                if (activeIndex < playlist.length - 1) {
                    setActiveIndex(() => activeIndex + 1)
                } else {
                    setActiveIndex(() => 0);
                }
                break;
            default:
                break;
        }
    }, [activeIndex, playlist]);

    const togglePlayPause = useCallback(() => {
        setIsPlaying(() => !isPlaying);
    }, [isPlaying]);

    const playTracks = useCallback((tracks: Track[], index = 0) => {
        setTracks(tracks, index);
        setIsPlaying(() => true);
    }, []);

    const setTracks = useCallback((tracks: Track[], index = 0) => {
        setPlaylist(() => tracks);
        setActiveIndex(() => index);
    }, []);

    return (
        <PlayerContext.Provider value={{
            volume,
            setVolume,
            activeIndex,
            setActiveIndex,
            activeDuration,
            setActiveDuration,
            isPlaying,
            setIsPlaying,
            position,
            setPosition,
            repeatMode,
            setRepeatMode,
            tracks: playlist,
            setTracks,
            nextTrack,
            togglePlayPause,
            playTracks
        }}>
            {children}
        </PlayerContext.Provider>
    )
}

export const usePlayerContext = () => {
    const context = React.useContext(PlayerContext);
    if (context === undefined) {
        throw new Error('usePlayerContext must be used within a PlayerProvider');
    }
    return context;
};